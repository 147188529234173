import React, { ChangeEvent, MutableRefObject, useCallback, useEffect, useState } from 'react';
import { Snack, Snackbar, SnackbarProvider, Typography } from 'fronton-react';
import { useStoreon } from 'storeon/react';
import log from 'loglevel';
import { load } from 'recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { createCookies, getLangFromCookies, patchUrlByLocale, getQaCookies } from 'utils';
import { sendGaSubscribedEvent } from 'utils/ga-events';
import { api, ApiRequestConfig } from '../../api';
import { State } from '../../store/types';
import { errorInput, focusedInput, FooterSubscribeWrap, policyTypography, subscribeHeader } from './styles';
import { FooterSubscribeProps, SnackType } from './types';
import { errorSnack, successSnack } from './snacks';
import { validateEmail } from '../../utils/validate-email';
import { SubscribeGroup } from './subscribe-group';

export const DATA_QA_FOOTER_SUBSCRIBE_SNACKBAR = 'footer-subscribe-snackbar';
export const DATA_QA_FOOTER_SUBSCRIBE_SNACK = 'footer-subscribe-snack';

export const RECAPTCHA_ERROR_MSG = 'Модуль recaptcha-v3 не загружен';
export const SESSION_TOKEN_ERROR_MSG = 'Не удалось получить session_token';

export const FooterSubscribe: React.FC<FooterSubscribeProps> = ({
  subscribersUrl,
  subscribersApiKey,
  recaptchaPublicKey,
  _mockSessionToken,
}) => {
  const { requestID } = useStoreon<State>('requestID');
  const { t } = useTranslation();
  // useSnackbar somehow doesn't work therefore I used useState
  const [currentSnack, setSnack] = useState<SnackType | null>(null);
  const [inputError, setInputError] = useState('');
  const [value, setValue] = useState('');
  const [focused, setFocused] = useState(false);
  const [, setSubscribed] = useState(false);
  const [sessionToken, setSessionToken] = useState(_mockSessionToken ?? '');
  const [groupClass, setGroupClass] = useState('');

  const onFocus = async () => {
    setFocused(true);
    if (!sessionToken) {
      try {
        const recaptcha = await load(recaptchaPublicKey, { autoHideBadge: true });
        const token = await recaptcha.execute('homepage');
        setSessionToken(token);
      } catch (error) {
        log.error(error, RECAPTCHA_ERROR_MSG);
      }
    }
  };

  const onSubmit =
    (inputRef: MutableRefObject<HTMLInputElement | null>) => async (e: { preventDefault: () => void }) => {
      e.preventDefault();
      setInputError('');
      if (!value) {
        setInputError(t('footer.subscription.error.notFound'));
        return;
      }

      if (!validateEmail(value)) {
        log.error('Не email');
        setInputError(t('footer.subscription.error.wrong'));
        return;
      }

      if (!sessionToken) {
        log.error(SESSION_TOKEN_ERROR_MSG);
        setSnack({ id: `error-${Math.random()}`, ...errorSnack });
        setTimeout(() => setSnack(null), 3000);
        return;
      }

      const options = {
        useCredentials: true,
        apiKey: subscribersApiKey,
        requestID,
      };
      const qaCookie = getQaCookies(document?.cookie);
      const lang = getLangFromCookies(createCookies(document?.cookie));

      const urlParams = new URLSearchParams({ lang });

      if (qaCookie) {
        urlParams.set('testId', qaCookie.split('=')[1]);
      }

      try {
        const response = await api.post(
          `${subscribersUrl}?${urlParams.toString()}`,
          {
            contact: value,
            gtoken: sessionToken,
          },
          options as ApiRequestConfig,
        );
        inputRef?.current?.blur();
        setSubscribed(true);
        sendGaSubscribedEvent();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (response?.message === 'Client already subscribed') {
          setInputError('Вы уже подписаны');
        } else {
          setSnack({ id: `success-${Math.random()}`, ...successSnack });
          setTimeout(() => setSnack(null), 3000);
        }
      } catch (error) {
        inputRef?.current?.blur();
        setSnack({ id: `error-${Math.random()}`, ...errorSnack });
        setTimeout(() => setSnack(null), 3000);
      } finally {
        setValue('');
        setSessionToken('');
      }
    };
  const onMailInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>, inputValue: string) => {
      setValue(inputValue);
      setInputError('');
    },
    [setValue, setInputError],
  );
  useEffect(() => {
    if (!!inputError) {
      setGroupClass(errorInput);
    } else if (focused) {
      setGroupClass(focusedInput);
    } else {
      setGroupClass('');
    }
  }, [inputError, focused]);

  return (
    <SnackbarProvider>
      <FooterSubscribeWrap>
        <Typography className={subscribeHeader} variant="h3" color="text-invert">
          {t('footer.subscription.header')}
        </Typography>
        <SubscribeGroup
          groupClass={groupClass}
          onSubmit={onSubmit}
          onFocus={onFocus}
          onMailInput={onMailInput}
          inputError={inputError}
          value={value}
          setFocused={setFocused}
        />
        <div>
          <Typography variant="caption" className={policyTypography} color="text-invert">
            {t('footer.subscription.offer.first-block')}{' '}
            <a href={patchUrlByLocale({ url: '/personal-data-docs/' })} rel="noopener noreferrer">
              {t('footer.subscription.offer.first-link')}
            </a>{' '}
            {t('footer.subscription.offer.second-block')}{' '}
            <a href="https://policies.google.com/privacy?hl=ru" rel="noopener noreferrer">
              {t('footer.subscription.offer.second-link')}
            </a>{' '}
            {t('footer.subscription.offer.third-block')}{' '}
            <a href="https://policies.google.com/terms?hl=ru" rel="noopener noreferrer">
              {t('footer.subscription.offer.third-link')}
            </a>
            .
          </Typography>
        </div>
        {currentSnack && (
          <Snackbar horizontalPosition="end" data-qa={DATA_QA_FOOTER_SUBSCRIBE_SNACKBAR}>
            <Snack
              key={currentSnack.id}
              id={currentSnack.id}
              paragraph={currentSnack.content}
              variant={currentSnack.variant}
              icon={currentSnack.icon}
              header={currentSnack.header}
              autoHideTimer={3000}
              data-qa={DATA_QA_FOOTER_SUBSCRIBE_SNACK}
            />
          </Snackbar>
        )}
      </FooterSubscribeWrap>
    </SnackbarProvider>
  );
};
