import { styled } from 'linaria/react';
import { css } from 'linaria';

export const FooterLayout = styled.div`
  background: var(--background-accent);
`;

export const UpperFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const footer = css`
  display: flex;
  flex-direction: column;
  padding-top: var(--space-400);
  padding-bottom: var(--space-500);
  @media (--only-mobile) {
    padding-bottom: var(--space-400);
  }
`;

export const socialLinks = css`
  margin-top: var(--space-200);

  @media (--from-tablet) {
    margin-top: var(--space-500);
  }
`;
