import React, {
  ChangeEvent,
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { RegularButton, Caption, Input } from 'fronton-react';
import { useBreakpoints } from 'common/hooks';
import { useTranslation } from 'react-i18next';
import { captionStyle, InputGroup, SubscribeBlock, subscribeInput } from './styles';

export const DATA_QA_EMAIL_INPUT = 'email-input';

export interface SubscribeGroupProps {
  onMailInput: (e: ChangeEvent<HTMLInputElement>, inputValue: string) => void;
  onFocus: () => void;
  setFocused: Dispatch<SetStateAction<boolean>>;
  onSubmit: (
    ref: MutableRefObject<HTMLInputElement | null>,
  ) => (e: { preventDefault: () => void }) => Promise<void>;
  value: string;
  inputError: string;
  groupClass: string;
}

export const SubscribeGroup: React.FC<SubscribeGroupProps> = ({
  onMailInput,
  onFocus,
  setFocused,
  onSubmit,
  value,
  inputError,
  groupClass,
}) => {
  const { mobile } = useBreakpoints() || {};
  const [size, setSize] = useState<'l' | 'm'>('m');
  const inputRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => setSize(mobile ? 'l' : 'm'), [mobile]);
  // TODO: Add enterKeyHint=done attribute after allows it
  return (
    <SubscribeBlock>
      <InputGroup className={groupClass}>
        <Input
          id="subscribe-block-email"
          ref={inputRef}
          type="email"
          inputMode="email"
          onChange={onMailInput}
          onFocus={onFocus}
          onBlur={() => setFocused(false)}
          onKeyDown={(event) => event.key === 'Enter' && onSubmit(inputRef)(event)}
          value={value}
          caption={
            <Caption
              data-qa="caption-error"
              error
              message={inputError}
              style={{ display: !!inputError ? 'block' : 'none' }}
              className={captionStyle}
            />
          }
          placeholder={t('footer.subscription.placeholder')}
          className={subscribeInput}
          inputSize={size}
          data-qa={DATA_QA_EMAIL_INPUT}
        />
      </InputGroup>
      <div>
        <RegularButton
          variant="accent"
          onSubmit={onSubmit(inputRef)}
          onClick={onSubmit(inputRef)}
          onTouchEnd={onSubmit(inputRef)}
          size={size}
          wide={mobile}
          data-qa="subscribe-button"
        >
          {t('footer.subscription.action')}
        </RegularButton>
      </div>
    </SubscribeBlock>
  );
};
