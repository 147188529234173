import React, { memo } from 'react';
import { StoreContext } from 'storeon/react';
import { Footer } from 'components';
import { CssBaseline } from 'components/css-baseline';
import { Store } from 'store/types';
import { ThemeProvider } from 'fronton-react';

export interface FooterContainerProps {
  store: Store;
}

export const FooterContainer = memo<FooterContainerProps>(({ store }) => (
  <CssBaseline>
    <ThemeProvider>
      <StoreContext.Provider value={store}>
        <Footer />
      </StoreContext.Provider>
    </ThemeProvider>
  </CssBaseline>
));
