import { styled } from 'linaria/react';
import { css } from 'linaria';

export const FooterSubscribeWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: var(--space-200) 0 var(--space-500);
  @media (--from-tablet) {
    align-items: flex-start;
    margin: var(--space-700) 0;
    width: 473px;
  }
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SubscribeBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: var(--space-200);
  width: 100%;
  @media (--from-tablet) {
    flex-direction: row;
  }
`;

export const policyTypography = css`
  opacity: 0.6;
  a {
    font-size: 12px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.2px;
    text-decoration: underline;
    color: var(--text-invert);
  }
`;

export const subscribeInput = css`
  background: var(--background-accent);
  @media (--only-mobile) {
    width: 100%;
    margin-bottom: var(--space-200);
  }
  @media (--from-tablet) {
    width: 333px;
    margin-right: var(--space-150);
  }
  fieldset {
    background: var(--background-primary);
    border-color: transparent !important;
  }
`;

export const errorInput = css`
  fieldset {
    border-color: var(--attention-primary) !important;
  }
`;
export const focusedInput = css`
  fieldset {
    border-color: var(--secondary-yellow-primary) !important;
  }
`;

export const subscribeHeader = css`
  margin-bottom: var(--space-300);
`;

export const captionStyle = css`
  display: flex !important;
  flex-direction: row;
`;
