import React from 'react';
import { EnvelopeIcon, WarningIcon } from '@fronton/icons-react';
import { Icon } from 'fronton-react';
import { SnackVariant } from './types';

export const successSnack = {
  content: 'Для подтверждения перейдите по ссылке в письме.',
  variant: SnackVariant.success,
  header: 'Спасибо за подписку!',
  icon: (
    <Icon size={24}>
      <EnvelopeIcon />
    </Icon>
  ),
};

export const errorSnack = {
  header: 'Что-то пошло не так',
  icon: (
    <Icon size={24}>
      <WarningIcon />
    </Icon>
  ),
  content: 'Попробуйте еще раз позже',
  variant: SnackVariant.error,
};

export const alreadySubSnack = {
  content: 'Подписка уже оформлена.',
  variant: SnackVariant.error,
};

export const notAnEmailSnack = {
  content: 'Некорректный email.',
  variant: SnackVariant.error,
};
