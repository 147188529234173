import { useEffect, useState } from 'react';
import { isAndroid, isHuawei, isIPhone } from 'utils/ua-detector';
import { getUserAgent } from 'utils/ua';

type Device = {
  iphone: boolean;
  android: boolean;
  huawei: boolean;
};

export const useDeviceDetector = () => {
  const [device, setDevice] = useState<Device>();
  useEffect(() => {
    const ua = getUserAgent();
    setDevice({
      iphone: isIPhone(ua),
      android: isAndroid(ua),
      huawei: isHuawei(ua),
    });
  }, []);
  return device || ({} as Device);
};
