import React from 'react';

export const McLogo: React.FC = () => (
  <svg width="32" height="25" viewBox="0 0 32 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.6">
      <path
        d="M15.7061 17.6934C15.7606 17.74 15.8189 17.7867 15.8753 17.8334C14.765 18.5722 13.4331 19.0039 12 19.0039C8.13444 19.0039 5 15.8695 5 12.0039C5 8.13835 8.13444 5.00391 12 5.00391C13.4331 5.00391 14.765 5.43557 15.8753 6.17446C15.8189 6.22113 15.7606 6.26585 15.7061 6.31446C14.0572 7.74946 13.1103 9.82224 13.1103 12.0039C13.1103 14.1856 14.0572 16.2584 15.7061 17.6934ZM20.6528 5.00391C19.2197 5.00391 17.8878 5.43557 16.7775 6.17446C16.8339 6.22113 16.8922 6.26585 16.9467 6.31446C18.5956 7.74946 19.5406 9.82224 19.5406 12.0039C19.5406 14.1856 18.5956 16.2584 16.9467 17.6934C16.8922 17.74 16.8339 17.7867 16.7775 17.8334C17.8878 18.5722 19.2197 19.0039 20.6528 19.0039C24.5183 19.0039 27.6528 15.8695 27.6528 12.0039C27.6528 8.13835 24.5183 5.00391 20.6528 5.00391ZM16.3264 6.50307C16.2369 6.57502 16.1475 6.64696 16.0619 6.7228C14.5861 8.00613 13.6528 9.89613 13.6528 12.0039C13.6528 14.1117 14.5861 16.0017 16.0619 17.285C16.1475 17.3609 16.2369 17.4328 16.3264 17.5047C16.4158 17.4328 16.5053 17.3609 16.5908 17.285C18.0667 16.0017 19 14.1117 19 12.0039C19 9.89613 18.0667 8.00613 16.5908 6.7228C16.5053 6.64696 16.4158 6.57502 16.3264 6.50307Z"
        fill="white"
      />
    </g>
  </svg>
);
