import React, { useRef } from 'react';
import { registerWidget } from '@okapi/core';
import { getGlobalRouter } from '@okapi/router-react';
import { FooterContainer } from 'entries/common';
import { createStore } from 'store';
import { CookiesEvents, GlobalEvents, FETCH_MODEL, SubscriptionEvents } from 'actions';
import { State } from 'store/types';
import { initI18n } from 'utils/init-i18n';

registerWidget<State>(`${__NAME__}/footer`, (state) => () => {
  const { current } = useRef(createStore());
  const router = getGlobalRouter();
  current.dispatch(FETCH_MODEL.success, state.model ?? undefined);
  current.dispatch(SubscriptionEvents.setRecaptchaParams, {
    subscribersUrl: state.subscribersUrl,
    recaptchaPublicKey: state.recaptchaPublicKey,
    subscribersApiKey: state.subscribersApiKey,
  });
  current.dispatch(CookiesEvents.set, document.cookie);
  current.dispatch(GlobalEvents.setPathname, state.path ?? '/');
  current.dispatch(GlobalEvents.setHost, state.host);
  initI18n(router.getPath(), state.host);

  return <FooterContainer store={current} />;
});
