import { styled } from 'linaria/react';
import { css } from 'linaria';
import { breakpoints } from 'common/styles/breakpoints';

export const AccordionContainer = styled.div``;

export const AccordionItemContainer = styled.div`
  position: relative;
  cursor: pointer;
  padding-top: var(--space-300);
  ${breakpoints.only.mobile} {
    padding-top: 0;
    width: 100%;
  }

  &:hover {
    border-color: var(--control-hover-minor);
    z-index: 1;
    color: var(--control-hover-primary);
  }
`;

export const heading = css`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  text-align: left;
  gap: var(--space-125);
  div[role='presentation'] {
    transition: 0.5s;
    transform: rotate(-180deg);
  }
  svg {
    opacity: 0.2;
  }
  & > * {
    margin: 0 !important;
  }
`;

export const content = css`
  display: flex;
  flex-direction: column;
  padding-top: var(--space-100);
  & > * {
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const contentClosed = css`
  .${heading} {
    div[role='presentation'] {
      transform: rotate(0);
    }
  }
`;

export const dividerGap = css`
  margin-top: var(--space-300);
  margin-bottom: var(--space-300);
  opacity: 0.20000000298023224;
`;
