import React, { memo } from 'react';
import { Typography } from 'fronton-react';
import { useOpenlinkUrl } from 'common/hooks';
import { ONELINK_URL_PATH_BY_DEVICE } from 'utils/onelink-url';
import { useTranslation } from 'react-i18next';
import { appText, downLink, DownloadLinks, FooterAppDesktopWrapper, PhoneBlock, upperLink } from './styles';

export const FooterAppBlockDesktop = memo(() => {
  const { t } = useTranslation();
  const googleplayUrl = useOpenlinkUrl(ONELINK_URL_PATH_BY_DEVICE.android.desktop, 'android', 'desktop');
  const appstoreUrl = useOpenlinkUrl(ONELINK_URL_PATH_BY_DEVICE.os.desktop, 'os', 'desktop');
  const appgalleryUrl = useOpenlinkUrl(ONELINK_URL_PATH_BY_DEVICE.huawei.desktop, 'huawei', 'desktop');

  return (
    <FooterAppDesktopWrapper>
      <PhoneBlock>
        <img
          src="//cdn.lemanapro.ru/lmru/image/upload/v1/other/navigation-microfronts/header-footer/qr.png/file.png"
          alt="download-qr-app"
        />
      </PhoneBlock>
      <DownloadLinks>
        <Typography size="subtitle" variant="m" color="text-invert" className={appText}>
          {t('footer.app.message')}
        </Typography>
        <a href={appstoreUrl} target="_blank" rel="noopener noreferrer" className={upperLink}>
          <img
            src="//cdn.lemanapro.ru/lmru/image/upload/v1/other/navigation-microfronts/header-footer/ios-desktop.png/file.png"
            alt="ios-desktop-app"
          />
        </a>
        <a href={googleplayUrl} target="_blank" rel="noopener noreferrer" className={upperLink}>
          <img
            src="//cdn.lemanapro.ru/lmru/image/upload/v1/other/navigation-microfronts/header-footer/gp-desktop.png/file.png"
            alt="gp-desktop-app"
          />
        </a>
        <a href={appgalleryUrl} target="_blank" rel="noopener noreferrer" className={downLink}>
          <img
            src="//cdn.lemanapro.ru/lmru/image/upload/v1/other/navigation-microfronts/header-footer/app-gallery-desktop.png/file.png"
            alt="ag-desktop-app"
          />
        </a>
      </DownloadLinks>
    </FooterAppDesktopWrapper>
  );
});
