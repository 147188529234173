import { analytics } from 'utils/analytics';

export const onMenuLinkClickCb = (linkName: string) => {
  analytics.sendEvent('GA:clickButton', {
    elementType: 'button',
    elementBlock: 'footer', // блок, в котором расположен элемент
    elementName: 'menu', // условное обозначение
    elementText: linkName, // название пункта меню
  });
};
