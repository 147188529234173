import React, { memo } from 'react';
import log from 'loglevel';
import { Layout } from 'fronton-react';
import { useStoreon } from 'storeon/react';
import { useHideMobileApp } from 'common/hooks/use-hide-mobile-app';
import { FooterList } from '../footer-list';
import { getIsSubscribeAvailable, selectCurrentRegion } from '../../selectors';
import { ModelJsonStore, SubscriptionStore } from '../../reducers';
import { FooterAppBlock, FooterAppBlockDesktop } from '../footer-app-block';
import { FooterSubscribe } from '../footer-subscribe/footer-subscribe';
import { FooterSocialLinks } from '../footer-social-links/footer-social-links';
import { footer, FooterLayout, UpperFooter, socialLinks } from './styles';

export const logParam = (param: string) => log.error(`${param} не получен`);

export const Footer: React.FC = memo(() => {
  const { model } = useStoreon<ModelJsonStore>('model');
  const { subscribersApiKey, subscribersUrl, recaptchaPublicKey } = useStoreon<SubscriptionStore>(
    'subscribersApiKey',
    'subscribersUrl',
    'recaptchaPublicKey',
  );

  const isHideMobileApp = useHideMobileApp();

  if (!subscribersApiKey) logParam('subscribersApiKey');
  if (!subscribersUrl) logParam('subscribersUrl');
  if (!recaptchaPublicKey) logParam('recaptchaPublicKey');

  const currentRegion = selectCurrentRegion(model?.regions, model?.properties?.regionId);
  const isSubscribeAvailable = getIsSubscribeAvailable(model?.experiments);

  if (!model?.footer || !currentRegion) return null;

  return (
    <div data-qa="footer-content">
      {!isHideMobileApp && <FooterAppBlock />}
      <FooterLayout>
        <Layout className={footer}>
          <UpperFooter>
            <FooterList menuItems={model} />
            {!isHideMobileApp && <FooterAppBlockDesktop />}
          </UpperFooter>
          {isSubscribeAvailable ? (
            <FooterSubscribe
              subscribersUrl={subscribersUrl}
              subscribersApiKey={subscribersApiKey}
              recaptchaPublicKey={recaptchaPublicKey}
            />
          ) : null}
          <FooterSocialLinks className={isSubscribeAvailable ? '' : socialLinks} links={model.socialMedia} />
        </Layout>
      </FooterLayout>
    </div>
  );
});
