export type FooterSubscribeProps = {
  subscribersApiKey: string;
  subscribersUrl: string;
  recaptchaPublicKey: string;
  _mockSessionToken?: string;
};

export enum SnackVariant {
  neutral = 'neutral',
  primary = 'primary',
  success = 'success',
  error = 'error',
}

export type SnackType = {
  content: string;
  id: string;
  variant?: SnackVariant;
  header: string;
  icon?: JSX.Element;
};
