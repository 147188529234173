import React from 'react';

export const AkitLogo: React.FC = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.6" clipPath="url(#clip0_4374_257492)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.37031 12.6093H4.5741L3.96783 11.2055L3.37031 12.6093ZM12.7264 14.6027H11.008L9.73778 12.3602C9.64915 12.3714 9.53309 12.3782 9.38902 12.3809C9.24505 12.3834 9.12062 12.3851 9.01558 12.3851V14.6027H7.49621V8.798H9.01558V11.0642C9.13716 11.0642 9.28249 11.063 9.45141 11.0601C9.62019 11.0574 9.75711 11.0284 9.86228 10.9731C9.99522 10.9065 10.0948 10.7806 10.1614 10.5951C10.2274 10.4097 10.3022 10.1422 10.3852 9.79346C10.4297 9.61643 10.5002 9.45148 10.5971 9.29937C10.6938 9.14703 10.8253 9.0225 10.9914 8.92549C11.1574 8.82898 11.3621 8.7648 11.6057 8.73442C11.8492 8.70401 12.1426 8.72475 12.4858 8.79704V9.81842C12.3031 9.80722 12.1508 9.82959 12.029 9.88492C11.9073 9.9404 11.8242 10.0564 11.78 10.2336C11.686 10.5828 11.5833 10.8832 11.4728 11.1351C11.3621 11.387 11.2266 11.6015 11.0659 11.7787C11.3484 12.2107 11.6251 12.644 11.8964 13.0786C12.1676 13.5134 12.4442 13.9468 12.7264 14.3787V14.6027ZM23.5339 10.1175H22.1989V14.6027H20.7292V10.1175H19.3015V8.798H23.403C23.1522 6.88521 22.634 5.08371 21.7646 3.6027C21.7566 3.58837 21.7489 3.57379 21.7408 3.55936C19.4103 -0.478827 13.071 -1.18179 7.5813 1.9892C2.67037 4.82581 0.103762 9.80136 1.28496 13.7099L3.59579 8.798H4.35645L6.96481 14.3952V14.6027H5.39592L5.05529 13.8801H2.8805L2.54818 14.6027H1.63018C1.70104 14.7514 1.77735 14.8983 1.86063 15.0427C1.86887 15.0571 1.87765 15.0709 1.88609 15.0851C4.65718 19.9705 12.2736 23.305 16.9576 23.9692C16.9559 23.9741 16.9594 23.9643 16.9576 23.9692C17.9484 24.0891 18.9849 23.9009 19.9156 23.3632C20.8464 22.8255 21.5276 22.0217 21.9192 21.1035C21.9142 21.1026 21.9241 21.1044 21.9192 21.1035C23.0232 18.3607 23.806 14.1069 23.5339 10.1175ZM18.5212 14.6027H17.0019V11.6043L13.9469 14.6192H13.4985V8.798H15.0178V11.629L18.0117 8.798H18.5212V14.6027Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_4374_257492">
        <rect width="24" height="24" fill="white" transform="translate(0 0.00390625)" />
      </clipPath>
    </defs>
  </svg>
);
