import React, { memo } from 'react';
import { FooterMenuItems } from 'types/model';
import { useTranslation } from 'react-i18next';
import { useHideBusiness } from 'common/hooks';
import { FooterMenu } from './styles';
import { FooterListTab } from './components';

export type FooterListProps = {
  menuItems: FooterMenuItems;
};

export const FooterList = memo<FooterListProps>(({ menuItems }) => {
  const { t } = useTranslation();
  const isHideBusiness = useHideBusiness();
  const isBusinessVisible = !isHideBusiness;
  const { company, business, customers } = menuItems;

  return (
    <>
      <FooterMenu>
        {menuItems && (
          <>
            <FooterListTab links={customers} metaContent="buyers" name={t('footer.links.headers.buyers')} />
            <FooterListTab
              links={company}
              metaContent="companyName"
              name={t('footer.links.headers.company')}
            />
            {isBusinessVisible && (
              <FooterListTab links={business} metaContent="business" name={t('footer.links.headers.b2b')} />
            )}
          </>
        )}
      </FooterMenu>
    </>
  );
});
