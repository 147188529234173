import { styled } from 'linaria/react';
import { css } from 'linaria';

export const AppBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 166px;
    height: 48px;
  }
`;

export const FooterAppWrapper = styled.div`
  margin-top: var(--space-1000);
  padding: var(--space-400) var(--space-200);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 246px;
  background: var(--background-secondary);
  @media (--from-tablet) {
    padding: var(--space-200) var(--space-700);
    height: 112px;
    flex-direction: row;
  }
  @media (--from-desktop) {
    display: none;
  }
`;

export const LogoBlock = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  @media (--from-tablet) {
    flex-direction: row;
  }
`;

export const logoWrap = css`
  width: var(--space-800);
  height: var(--space-800);
  border-radius: var(--rounding-l);
  background: var(--background-primary);
  box-shadow: var(--box-shadow-s);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const roundLogoWrap = css`
  width: var(--space-800);
  height: var(--space-800);
  background: var(--background-primary);
  box-shadow: var(--box-shadow-s);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--space-800);
`;

export const logoWithBadge = css`
  margin-bottom: var(--space-200);
  position: relative;
  @media (--from-tablet) {
    margin-bottom: 0;
    margin-right: var(--space-300);
  }
`;

export const lemanaProLogo = css`
  svg {
    width: var(--space-675);
    height: var(--space-525);
  }
`;

export const logoBadge = css`
  position: absolute;
  top: -4px;
  right: -4px;
  @media (--from-tablet) {
    top: var(--space-50);
    right: var(--space-50);
  }
`;

export const roundLogoBadge = css`
  position: absolute;
  top: var(--space-50);
  right: var(--space-50);
`;

export const logoText = css`
  color: var(--text-primary);
`;

export const PhoneBlock = styled.div`
  margin-right: var(--space-400);
  img {
    width: 128px;
    height: 253px;
  }
`;
export const DownloadLinks = styled.div`
  width: 165px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 176px;
  padding-top: var(--space-300);
  img {
    width: 140px;
    height: 40px;
  }
`;

export const FooterAppDesktopWrapper = styled.div`
  display: none;
  @media (--from-desktop) {
    width: 356px;
    height: 307px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const appText = css`
  margin-bottom: var(--space-200) !important;
`;

export const upperLink = css`
  margin-bottom: var(--space-150);
  cursor: pointer;
`;

export const downLink = css`
  cursor: pointer;
`;
