import React, { useCallback, useState } from 'react';
import { Divider, Typography } from 'fronton-react';
import { ChevronDownIcon } from '@fronton/icons-react';
import AnimateHeight from 'react-animate-height';
import { AccordionItemContainer, content, contentClosed, dividerGap, heading } from './style';

export const DATA_QA_ACCORDION_ITEM_CONTAINER = 'accordion-item-container';

type Props = {
  title: JSX.Element | string;
  onClick?: (open: boolean) => void;
  children: JSX.Element | JSX.Element[] | string;
};

export const AccordionItem = ({ title, children, onClick }: Props) => {
  const [open, setOpen] = useState(false);
  const [height, setHeight] = useState<number | 'auto'>(0);
  const handleClick = useCallback(() => {
    setOpen(!open);
    setHeight(height === 0 ? 'auto' : 0);
    onClick?.(open);
  }, [open, height, onClick]);
  return (
    <AccordionItemContainer
      className={open ? '' : contentClosed}
      data-qa={DATA_QA_ACCORDION_ITEM_CONTAINER}
      aria-expanded={open}
      aria-controls="panel"
      onClick={handleClick}
    >
      <div className={heading} data-qa="accordion-item-header">
        <Typography variant="m" size="body_accent" as="p" color="text-invert">
          {title}
        </Typography>
        <ChevronDownIcon
          type="outline"
          size="l"
          color="text-invert"
          aria-label={open ? `Скрыть ${title}` : `Показать ${title}`}
        />
      </div>
      <AnimateHeight id="panel" duration={500} height={height}>
        <div data-qa="accordion-item-content" className={content}>
          {children}
        </div>
      </AnimateHeight>
      <Divider className={dividerGap} />
    </AccordionItemContainer>
  );
};
