import React from 'react';

export const VisaLogo: React.FC = () => (
  <svg width="32" height="25" viewBox="0 0 32 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.6">
      <path
        d="M12.809 7.15466L8.73869 16.8532H6.07538L4.06533 9.11446C3.96482 8.6622 3.81407 8.46119 3.46231 8.26019C2.8593 7.95868 1.85427 7.60692 1 7.45617L1.05025 7.15466H5.32161C5.87437 7.15466 6.37689 7.50642 6.47739 8.15969L7.53266 13.7878L10.1457 7.20491H12.809V7.15466ZM23.2111 13.6873C23.2111 11.1245 19.6935 10.9738 19.6935 9.86823C19.6935 9.51647 20.0452 9.16471 20.7487 9.06421C21.1005 9.01396 22.1055 8.96371 23.2111 9.51647L23.6633 7.45617C23.0603 7.25516 22.3065 7.00391 21.3015 7.00391C18.8392 7.00391 17.0804 8.31044 17.0804 10.22C17.0804 11.627 18.3367 12.3808 19.2915 12.8331C20.2462 13.2853 20.598 13.5868 20.598 14.0391C20.598 14.6923 19.8442 14.9939 19.0905 14.9939C17.8342 14.9939 17.0804 14.6421 16.5276 14.3908L16.0754 16.5014C16.6784 16.7527 17.7337 17.0039 18.8392 17.0039C21.4523 17.0039 23.1608 15.6974 23.2111 13.6873ZM29.7437 16.8532H32.0553L30.0452 7.15466H27.8844C27.3819 7.15466 26.9799 7.45617 26.8291 7.85818L23.0603 16.8532H25.6734L26.1759 15.3959H29.392L29.7437 16.8532ZM26.9296 13.4361L28.2362 9.81798L28.99 13.4361H26.9296ZM16.3769 7.15466L14.3166 16.8532H11.804L13.8643 7.15466H16.3769Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath>
        <rect width="32" height="24" fill="white" transform="translate(0 0.00390625)" />
      </clipPath>
    </defs>
  </svg>
);
