import { styled } from 'linaria/react';

export const FooterMenu = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (--from-tablet) {
    flex-direction: row;
    justify-content: space-between;
    padding-top: var(--space-300);
    gap: var(--space-400);
  }
  @media (--from-desktop) {
    padding-top: var(--space-200);
    margin-right: var(--space-1000);
  }
`;
